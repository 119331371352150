<template>
    <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('audio.unpublished') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <b-overlay :show="showOverlay">
            <MediaGridCMS :media="media" />
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue";
import MediaGridCMS from '@/components/admin/_utils_/MediaGridCMS.vue'

export default {
    name: "UnpublishedAudio",
    components: { NavBar, MediaGridCMS },
    data() {
        return {
          showOverlay: true,
          media: []
        }
    },
    async created() {
      await this.$http.get('/cms/api/media/audio/unpublished/', { headers: {Authorization: this.getAuthData().accessToken }})
          .then(response => {
            this.media = response.data.content;
          })
          .catch(error => {
            this.handleError(error);
          });
      this.showOverlay = false;        
    }
}
</script>

<style></style>
